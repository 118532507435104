<nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href=''>
        <img class="logo" src="../../../../assets/images/isotipo.svg" alt="logo">
        <h1>Outline Market</h1>
      </a>
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
<div class="navbar-menu">
      <div class="navbar-start">
        <a class="navbar-item">
              <div class="dropdown is-hoverable">
                <div class="dropdown-trigger">
                  <button class="button is-outlined" aria-haspopup="true" aria-controls="dropdown-menu3">
                    <span>Categorias</span>
                    <span class="icon is-small">
                      <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                  <div class="dropdown-content">
                    <a class="dropdown-item"><app-categories></app-categories></a>
                  </div>
                </div>
              </div>
            </a>
            
      </div>
      <div class="navbar-end">
            <input class="input is-rounded" type="text" placeholder="Buscar producto">
            <div class="navbar-item">
                <a href=""><img src="../../../../assets/images/cart.svg"/></a>
            </div>
        </div>
      </div>
</nav>
