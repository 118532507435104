import { Component, OnInit } from '@angular/core';
import {PRODUCTS} from './mock-product';
import {ProductService} from './products.service';
import {Product} from './products';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  products: Product[] = [];

  constructor(private productService: ProductService) {}

  getProducts():void {
    this.products = this.productService.getProducts()
  }


  ngOnInit(): void {
    this.getProducts();
  }

}
