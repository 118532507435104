import { Injectable } from '@angular/core';
import {PRODUCTS} from './mock-product';
import {Product} from './products';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  getProducts(): Product[] {
    return PRODUCTS;
  }
}
;