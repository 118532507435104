<section class="container">
    <div class="columns is-mobile">
        <div class="column" *ngFor='let product of products'>
            <img class="column__image" [src]="product.image" alt="">
            <div class="column__details">
                <div class="column__details--title">
                    <a>{{product.name | slice:0:30}}</a>
                </div>
                <div class="column__details--seller">
                    <a>{{product.seller}}</a>
                </div>
                <div class="column__details--price">
                    <p>{{product.price | currency:'MX'}}</p> 
                </div>
            </div>
            
        </div>
    </div>
</section>

