import { Injectable } from '@angular/core';
import {CATEGORIES} from './mock-categories';
import {Categorie} from './categories';

@Injectable({
  providedIn: 'root'
})
export class CategorieService {

  getCategories(): Categorie[] {
    return CATEGORIES;
  }
}
;