import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/components/home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { ProductsComponent } from './products/products.component';

import { SharedModule } from './shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SubcategoriesComponent } from './subcategories/subcategories.component';
import { OpinionsComponent } from './opinions/opinions.component';
import { SellersComponent } from './sellers/sellers.component';
import { PurchasesComponent } from './purchases/purchases.component';


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HomeComponent,
    ProductsComponent,
    SubcategoriesComponent,
    OpinionsComponent,
    SellersComponent,
    PurchasesComponent,
    
    
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    SharedModule,
    BrowserModule,
    RouterModule,
    FontAwesomeModule,
  ],

  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
