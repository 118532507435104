import { Component, OnInit } from '@angular/core';
import { CATEGORIES} from './mock-categories';
import {CategorieService} from './categories.service';
import { Categorie } from './categories';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  categories: Categorie[] = [];

  constructor(private categorieService: CategorieService) {}

  getCategories():void {
    this.categories = this.categorieService.getCategories()
  }
  ngOnInit(): void {
    this.getCategories();
  }

}
