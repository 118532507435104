import {Product} from './products';

export const PRODUCTS: Product[] = [
    {
        id:'9ashdf9hafs',
        name:'(mock) Camisa Outline',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id tristique augue.',
        image:'../../assets/images/camisa.png',
        isNew:true,
        numberSales:456,
        rating:10,
        price:300.00,
        available:false,
        stock:100,
        category:'Ropa y Calzado',
        subcategory:'',
        seller:'Outline',
    },
    {
        id:'8adsh7ads',
        name:'(mock) Camisa Maki',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id tristique augue.',
        image:'../assets/images/camisa-maki.png',
        isNew:true,
        numberSales:123,
        rating:10,
        price:300.00,
        available:true,
        stock:40,
        category:'Ropa y Calzado',
        subcategory:'',
        seller:'Outline',
    },
    {
        id:'1y78fsdf',
        name:'(mock) Camisa Deploy',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id tristique augue.',
        image:'../assets/images/camisa-deploy.png',
        isNew:true,
        numberSales:163,
        rating:8,
        price:300.00,
        available:true,
        stock:56,
        category:'Ropa y Calzado',
        subcategory:'',
        seller:'Outline',
    },
    {
        id:'986gadsd',
        name:'(mock) Comic Colorado',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id tristique augue.',
        image:'../assets/images/comic.jpg',
        isNew:true,
        numberSales:874,
        rating:10,
        price:250.00,
        available:false,
        stock:25,
        category:'Prints',
        subcategory:'',
        seller:'Alder Gutierrez',
    },
]