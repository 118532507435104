
      <!-- <p>© 2021 Outline. Todos los derechos reservados.</p> -->
      <footer>
        <p>© 2021 Outline. Todos los derechos reservados.</p>
        <div class="redes">
          <a href="https://www.facebook.com/outlinecc/" target="_blank"><img src="../../../../assets/images/face.svg" alt=""></a>
          <a href="https://twitter.com/outlinecc" target="_blank"><img src="../../../../assets/images/icons8-twitter.svg" alt=""></a>
          <a href="https://www.instagram.com/outlinecc/?hl=es" target="_blank"><img src="../../../../assets/images/insta.svg" alt=""></a>
          <a href="https://www.twitch.tv/outlinecc" target="_blank"><img src="../../../../assets/images/icons8-twitch.svg" alt=""></a>
        </div>
      </footer>
  



