import {Categorie} from './categories';

export const CATEGORIES: Categorie[] = [
    {
        id:'12f343frf',
        name:'Ropa y Calzado',
        description:'Ropita chidita de artistas locales',
        image:'categories/{ID}' 
    },
    {
        id:'87af78fas',
        name:'Prints',
        description:'Ropita chidita de artistas locales',
        image:'categories/{ID}' 
    },
    {
        id:'97ahfsd9',
        name:'Pines',
        description:'Ropita chidita de artistas locales',
        image:'categories/{ID}' 
    },
    {
        id:'asdf87g',
        name:'Bolsitas Ecologicas',
        description:'Ropita chidita de artistas locales',
        image:'categories/{ID}' 
    }
]